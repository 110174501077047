import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import InformationLayout from "../../layouts/InformationLayout.jsx";
import indexData from "./index.json";
import Downloads from "../../components/_ui/Downloads.jsx";
export const _frontmatter = {
  "title": "Pergola Assembly - Staking out & positioning of the posts"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children,
  pagesList
}) => <InformationLayout pagesList={indexData.pageIndex}>
    {children}
  </InformationLayout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Step 1. Staking out & positioning of the posts`}</h1>
    <p>{`Once you have prepared and measured the site it is a good idea to stake out the area (Fig 6) and mark clearly where your posts will be situated.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/staking-and-positioning/fig-new62.jpg" alt="A diagram demonstrating the footprint area required for installing a pergola against a wall; It shows an example of a 2427mm x 2162mm" />
  <span className="text-metadata">Fig 6</span>
    </div>
    <h4>{`Positioning of the Posts:`}</h4>
    <p>{`Using a tape measure or a piece of string, mark a spot exactly 2427mm from the wall using a builder’s square to ensure that the line is at right angles (90 degrees) to the wall. This will be the position for the first Fixing Post. Once you have found the spot, either bang into the ground the ‘spiked’ Fixing Post or mark the spot carefully if you need to dig a hole for the ‘concrete-in’ Fixing Post. If you are using the ‘bolt-down’ Fixing Post onto a patio or hard surface, simply put a cross using some chalk, marking the centre of where the Fixing Post will be positioned.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/staking-and-positioning/n-fig7.jpg" alt="A diagram demonstrating how to position post fixing systems in relation to the wall, from a birds-eye perspective; the center of the fixing posts should be 2427mm from the wall." />
  <span className="text-metadata">Fig 7</span>
    </div>
    <p>{`To mark the position of the second Fixing Post take your piece of string or tape and mark out 2160mm from the centre of the first Fixing Post to the centre of the second fixing post, parallel to the wall, again using your builders square to ensure that the two Fixing Posts are aligned. To make sure that the footprint is completely straight use the builders square to check that all corners are 90 degrees.`}</p>
    <p>{`Depending upon the length of the pergola, and where it will be positioned on the wall of your house, you will see from Fig 8 below how the ‘footprint’ of the pergola will look.`}</p>
    <div className="captioned">
  <img src="/img/pergola-assembly/staking-and-positioning/fig88.jpg" alt="A diagram displaying the ideal positionning of the post fixing system in relation to the first post. There should be 2160mm between the centers of the two fixing posts, and 680mm between each rafter" />
  <span className="text-metadata">Fig 8</span>
    </div>
    <p><em parentName="p">{`(`}{`*`}{`NB these drawings are for representation purposes only and not to scale)`}</em></p>
    <h2>{`Downloads & Support`}</h2>
    <Downloads files={[{
      filename: "Pergola assembly guide",
      path: "/downloads/ASSEMBLY.pdf"
    }]} mdxType="Downloads" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      